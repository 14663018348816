import Blog1 from '../assets/blog/blog-01.jpg'
import Blog2 from '../assets/blog//blog-02.jpg'
import Blog3 from '../assets/blog/blog-03.jpg'
import Blog4 from '../assets/blog/blog-04.jpg'
import Blog5 from '../assets/blog/blog-05.jpg'
import Blog6 from '../assets/blog/blog-06.jpg'


export const blogData = [
    {
        id: 1,
        image: Blog1,
        tag: "Technology",
        date: "01 Oct, 2024",
        title: "How is technology working with new things?",

    },
    {
        id: 2,
        image: Blog2,
        tag: "Technology",
        date: "02 Oct, 2024",
        title: "Top 10 important tips on IT servies & design?",

    },
    {
        id: 3,
        image: Blog3,
        tag: "Technology",
        date: "03 Oct, 2024",
        title: "How our comapany works in different ways?",

    },
    {
        id: 4,
        image: Blog4,
        tag: "Technology",
        date: "04 Oct, 2024",
        title: "How is technology working with new things?",

    },
    {
        id: 5,
        image: Blog5,
        tag: "Technology",
        date: "05 Oct, 2024",
        title: "Top 10 important tips on IT servies & design?",

    },
    {
        id: 6,
        image: Blog6,
        tag: "Technology",
        date: "06 Oct, 2024",
        title: "How our comapany works in different ways?",

    },
]