import sachdeva from '../assets/abouts/sachdeva.jpg'
import aicte from '../assets/abouts/aicte.jpg'
import spenny from '../assets/abouts/spenny.jpg'
import hood from '../assets/abouts/hood-app.jpg'
import bna from '../assets/abouts/bna.jpg'
import tripura from '../assets/abouts/tripura.jpg'

export const achievementData = [
    {
        id : 1,
        desc: "Meetup with sachdeva stocks owner Mr. Paramjeet sachdeva Sir whose company's listed in India's top 10 stocks ",
        image : sachdeva,

    },
    {
        id : 2,
        desc: "Meetup with Ex AICTE VICE CHAIRMAN Mr Dr. M.P.  Poonia Sir ",
        image : aicte,

    },
    {
        id : 3,
        desc: "Meetup with Spenny founder Mr Rathan Shah whose company valuation 100cr",
        image : spenny,

    },
    {
        id : 4,
        desc:  "Meetup with Hood app founder Mr Jasveer Singh  whose company valuation 600cr",
        image : hood,

    },
    {
        id : 5,
        desc: "Meet up with BNA President Mr Anoop Maurya ",
        image : bna,

    },
    {
        id : 6,
        desc:   "Meet up with Cabinet Minister of Tripura Shri Shukla Charan Nautiyal sir",
        image : tripura,

    },
    
    

]