import React from 'react'
import Team from '../../components/about1/Team'

const OurTeam = () => {
    return (
        <div>
            <Team />
        </div>
    )
}

export default OurTeam